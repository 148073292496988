import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import {
	axiosErrorToMessage,
	defaultsToArticleOption,
	defaultsToArticleOptionForOrder,
	getAvailability,
	numberD,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { SelectTableModalComponent } from 'src/app/components/select-table-modal/select-table-modal.component';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';
import { Route, Router } from '@angular/router';
import { RecomendationArticleModalComponent } from 'src/app/components/recomendation-article-modal/recomendation-article-modal.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import {
	AddCouponArticleDialogComponent,
	AddToBasketCoupon,
} from 'src/app/components/add-coupon-article-dialog/add-coupon-article-dialog.component';
import { DisplayMode } from 'src/smoothr-web-app-core/enums/DisplayMode';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';
enum toggleTags {
	All = 'all',
	Vegeterian = 'veggie',
}
@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss'],
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild('articlesList', { static: false }) articleListContent;
	@ViewChild(IonSearchbar, { static: false }) searchBar;
	@ViewChild('categoryList', { static: false }) categoryList;
	@ViewChild('categoryFilters', { static: false }) categoryFilters;

	environment = environment;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	PreorderType = PreorderType;
	searchBarOpen = false;
	public isLargeScreen = AppComponent.largeScreen;
	timerScrollTo = null;
	toggleTagsEnum = toggleTags;
	selectedToggleTag = toggleTags.All;
	showFilters = false;
	get preorderType(): PreorderType {
		return this.order?.preorder?.type;
	}
	swipeCoord = [0, 0];
	swipeTime = new Date().getTime();
	listFilters = [
		{
			value: 'warm',
			name: 'filter_section.filters_options.warm',
		},
		{
			value: 'cold',
			name: 'filter_section.filters_options.cold',
		},
		{
			value: 'fish',
			name: 'filter_section.filters_options.fish',
		},
		{
			value: 'chicken',
			name: 'filter_section.filters_options.chicken',
		},
		{
			value: 'beef',
			name: 'filter_section.filters_options.beef',
		},
		{
			value: 'pork',
			name: 'filter_section.filters_options.pork',
		},
	];
	selectedFilters: string[] = [];
	addedFilters: string[] = [];
	searchField = new FormControl('');
	constructor(
		public translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private analytics: AnalyticsService,
		private navigationService: NavigationService
	) {
		super(repository);
	}
	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	private _selectedCategoryIndex = 0;

	get selectedCategoryIndex(): number {
		return this._selectedCategoryIndex;
	}

	set selectedCategoryIndex(value) {
		if (this._selectedCategoryIndex === value) {
			return;
		}
		this._selectedCategoryIndex = value;
		this.scrollTo(value);
	}

	get selectedCategory(): ArticleCategory {
		return this.categories[this.selectedCategoryIndex];
	}

	get active(): boolean {
		return (
			this.order &&
			OrderUtils.articleGroupsTotalPrice(
				this.order.orderedArticles,
				this.order.type,
				this.order.preorder.type,
				this.order.terminalorder ? this.order.terminalorder.type : null
			) > 0
		);
	}

	get index(): number {
		return this.categories.length > 0 ? this.categories.findIndex(category => category?._id === this.selectedCategory?._id) : 0;
	}

	async ngOnInit() {
		await super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + NAVIGATIONURLS.order():
				break;
			case '/' + NAVIGATIONURLS.paymentCancel():
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + NAVIGATIONURLS.paymentFail():
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}

		this.loading = true;
		this.repository.venue.emit(
			await this.repository.getVenue(this.order && this.order.venue ? this.order.venue : environment.customerGroup[0] + '_master')
		);
		this.reloadCategories();
		this.openSelectTableModal();
		this.checkVenue();
		this.loading = false;
		this.searchField.valueChanges.pipe(debounceTime(700)).subscribe(v => {
			this.searchTerm = v;
			this.reloadCategories();
		});
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			await sleep(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		if (this.selectedCategoryIndex !== index) {
			await this.scrollArticleListTo(index);

			await this.triggerScrollWithDebounce(index);
			// await sleep(100);
		}
		this.scrollEvents = true;
	}
	triggerScrollWithDebounce(index) {
		setTimeout(() => {
			this.selectedCategoryIndex = index;

			this.scrollCategoryListTo(index); // Execute the function after a debounce
		}, 1); // Adjust the debounce time as needed (300 milliseconds in this example)
	}
	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => (this.isLargeScreen ? el.offsetTop - 100 : el.offsetTop - 15))
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category?._id === this.selectedCategory?._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			await this.triggerScrollWithDebounce(categoryIndex);
		}
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList ||
			!this.categoryList.nativeElement ||
			!this.categoryList.nativeElement.children[index] ||
			(!this.categoryList.nativeElement.scrollTo && !this.categoryList.nativeElement.scrollLeft)
		) {
			return;
		}

		const offsetLeft = this.categoryList?.nativeElement?.children[index]?.offsetLeft ?? 0;

		this.categoryList.nativeElement.scrollTo(offsetLeft - 25, 300);
		await sleep(100);
	}
	async openModal(item: Article, showRec: boolean = true, hideOptions: boolean = false) {
		if (this.loading) {
			return;
		}

		const articleGroup = new ArticleGroup();
		articleGroup.article = item;

		articleGroup.groups.push(...defaultsToArticleOption(item, [], item.defaults, this.order?.type, this.order.preorder.type));

		articleGroup.quantity = 1;
		console.log(articleGroup);
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup,
				showOnlyRequiredGroups: hideOptions,
			},
			mode: 'ios',
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);

			this.repository.onOrderChange(this.order);
			sleep(300);
			const copyGroup = response.data.articleGroup;
			this.addPfand(JSON.parse(JSON.stringify(copyGroup)));
			if (showRec) {
				const mainRecommendations = item.mainRecommendations;
				const categoryArticles = [].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[];
				const categoryArticlesMap = new Map(categoryArticles.map(it => [it._id, it]));
				if (mainRecommendations.length > 0) {
					const recommendedArticles: Article[] = [];

					mainRecommendations.forEach(it => {
						if (categoryArticlesMap.has(it)) {
							recommendedArticles.push(categoryArticlesMap.get(it));
						}
					});
					const result = await RecomendationArticleModalComponent.show(this.modalCtrl, recommendedArticles, this.order);
					await sleep(100);
					this.modalCtrl.dismiss();
					if (result?.article) {
						this.openModal(result.article, false, true);
					}
				}
			}
		}
	}
	async createArticlePfand(number: number, quantity: number = 1) {
		console.log(number);
		const articlePfand = this.venue.articleCategories
			.find(
				it =>
					it.articles.length > 0 &&
					it.articles.find(it => it.tags.find(it => it.identifier.includes('deposit' + number.toString().substring(2))))
			)
			?.articles?.find(article => {
				return article.tags.find(it => it.identifier.includes('deposit' + number.toString().substring(2)));
			});
		if (articlePfand) {
			const articleGroupPfand = new ArticleGroup();
			articleGroupPfand.article = articlePfand;
			articleGroupPfand.quantity = quantity;
			articleGroupPfand.groups = defaultsToArticleOptionForOrder(articlePfand, [], articlePfand.defaults, this.order);
			const index = this.order.orderedArticles.findIndex(orderedArticle => {
				return (
					orderedArticle.article._id === articleGroupPfand.article._id &&
					articleGroupPfand.groups.length === orderedArticle.groups.length &&
					articleGroupPfand.groups
						.map(
							option =>
								orderedArticle.groups.findIndex(orderedOption => {
									return (
										option.article._id === orderedOption.article._id &&
										option.quantity === orderedOption.quantity &&
										option.dependency === orderedOption.dependency &&
										option.dependsOn === orderedOption.dependsOn &&
										option.dependencyNumber === orderedOption.dependencyNumber
									);
								}) >= 0
						)
						.reduce((previousValue, currentValue) => previousValue && currentValue, true)
				);
			});
			if (index >= 0) {
				this.order.orderedArticles[index].quantity++;
			} else {
				this.order.orderedArticles.push(articleGroupPfand);
			}
			this.repository.onOrderChange(this.order);
		}
	}
	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		console.log(this.searchTerm);
		this.reloadCategories();
	}

	reloadCategories() {
		try {
			if (!this.venue) {
				this.categories = [];
				return;
			}
			const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
			let categoryList = this.venue.articleCategories

				.filter(category => !category.hidden)

				.map(category => {
					const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
					cat.articles = cat.articles.filter(article => {
						const available = getAvailability(article, OrderType.PREORDER, this.preorderType);
						if (lowerSearchTerm) {
							const keys = [
								article.name[this.translate.currentLang].toLocaleLowerCase(),
								cat.name[this.translate.currentLang].toLocaleLowerCase(),
							];
							return available && keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
						}
						return available;
					});

					if (this.selectedToggleTag !== toggleTags.All) {
						cat.articles = cat.articles.filter(article => {
							if (this.selectedToggleTag === toggleTags.Vegeterian) {
								return article.compound?.features?.vegetarian || article.compound?.features?.vegan;
							} else {
								return false;
							}
						});
					}

					if (this.selectedFilters.length > 0) {
						cat.articles = cat.articles.filter(article => {
							let articleFilter = false;
							this.selectedFilters.forEach(it => {
								if (article.compound.features[it] && article.compound.features[it] == true) {
									articleFilter = true;
								}
							});
							return articleFilter;
						});
					}

					return cat;
				})

				.filter(category => category.articles.length > 0);

			console.log(categoryList);
			if (!this.venue.couponsEnabled) {
				categoryList = categoryList.filter(it => it.displayMode !== 'code');
			}
			this.categories = categoryList;
			console.log('cateogries', this.categories);
			return;
		} catch (e) {
			console.log(e);
		}
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(this.order && this.order.venue)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {
					this.reloadCategories();
					this.selectedCategoryIndex = 0;
				});
			return;
		}
		this.reloadCategories();
		this.selectedCategoryIndex = 0;
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	private async scrollArticleListTo(index: number) {
		if (!this.articleList) {
			return;
		}
		const value = await this.articleList.getScrollElement();

		// const a = [].slice.call(this.articleList.el.children).forEach((it, tindex) => {
		// 	console.log('MAIN:', index, tindex, it.offsetTop);
		// });
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop, 1000);
	}
	async goHome() {
		await this.repository.cleanRepository();
		await this.navigationService.home();
	}
	openSearch() {
		this.searchBarOpen = true;
		this.showFilters = false;
	}
	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}

	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}

	async openSelectTableModal(openByToolbar?: boolean) {}
	async addPfand(articleGroup: ArticleGroup) {}
	async checkVenue() {
		if (this.venue) {
			const isOpenResturant = TimeUtils.doesHoursMatchNow(this.venue.openingHours);
			if (!isOpenResturant) {
				this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
					duration: 5000,
				});
				this.navigationService.home();
			}
		}
	}

	findFeature(category: ArticleCategory) {
		if (category?.features) {
			delete category.features?._id;
			delete category.features?.updatedAt;
			delete category.features?.createdAt;

			return Object.entries(category?.features)
				.filter(it => it[0] == 'new' || it[0] == 'special' || it[0] == 'topSelling')
				.filter(it => !!it[1])
				.map(it => it[0])[0];
		}
		return '';
	}
	toggleSwitch(selectedItem: toggleTags) {
		this.selectedToggleTag = selectedItem;
		this.reloadCategories();
	}
	selectFilter(value: string) {
		if (this.addedFilters.includes(value)) {
			this.addedFilters = this.addedFilters.filter(it => it !== value);
		} else {
			this.addedFilters.push(value);
		}
		// this.selectFilters = value;
	}
	addFilters() {
		this.selectedFilters = this.addedFilters;
		this.reloadCategories();
		this.showFilters = false;
	}
	dropFilters() {
		this.showFilters = false;
		this.addedFilters = [];
		this.selectedFilters = [];
		this.reloadCategories();
	}
	trackBy(_index: number, item: Article) {
		return item._id;
	}

	swipe(e: TouchEvent, when: string) {
		const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
		const time = new Date().getTime();

		if (when === 'start') {
			this.swipeCoord = coord;
			this.swipeTime = time;
		} else if (when === 'end') {
			const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
			const duration = time - this.swipeTime;
			if (
				duration < 1000 && //
				Math.abs(direction[0]) > 30 && // Long enough
				Math.abs(direction[0]) > Math.abs(direction[1] * 3)
			) {
				// Horizontal enough
				const swipeDir = direction[0] > 0 ? 'next' : 'previous';
				if (swipeDir === 'next') {
					this.toggleSwitch(this.toggleTagsEnum.Vegeterian);
				} else {
					this.toggleSwitch(this.toggleTagsEnum.All);
				}
			}
		}
	}
	async toMap() {
		await sleep(500);

		if (this.repository.enterBySlug$.getValue()) {
			this.navigationService.slugNav(this.repository.enterBySlug$.getValue());
			return;
		}
		this.navigationService.map();
	}
	toggleSwitchClick() {
		this.toggleSwitch(this.selectedToggleTag === this.toggleTagsEnum.All ? this.toggleTagsEnum.Vegeterian : this.toggleTagsEnum.All);
	}
	private async validateCoupon(data: { ean: string; orderedArticles: string[] }, venueId: string) {
		try {
			return await Api.validateCoupon(data, venueId);
		} catch (e) {
			console.log(e.response.data.message);
			await InfoModalComponent.show(this.modalCtrl, {
				title: 'Error',
				info: e?.response?.data?.message ?? 'Sorry, smth go wrong',
				mainButton: null,
				closeButton: this.translate.instant('email_confirmation.close'),
			});
			return null;
		}
	}
	async addArticleCoupon(value: string) {
		console.log('ADD', value);
		const articlesList = this.order.orderedArticles.map(it => it.article._id);
		// articlesList.push(value);
		// const validateCoupon = await this.validateCoupon({ ean: value.toString(), orderedArticles: articlesList }, this.venue._id)
		// if (!validateCoupon) {
		// 	return;
		// }
		// return;
		let foundArticle: Article = null;
		this.venue.articleCategories.forEach(category => {
			const foundArticleInList = category.articles.find(article => article.gtin == value);
			if (foundArticleInList) {
				foundArticle = foundArticleInList;
			}
		});
		console.log(value, foundArticle);
		if (foundArticle) {
			const dialog = await AddCouponArticleDialogComponent.show(this.modalCtrl, foundArticle, this.order);
			if (!dialog) {
				return;
			}

			if (dialog === AddToBasketCoupon.SELECT_ANOTHER) {
			}
			const articleGroup = new ArticleGroup();
			articleGroup.article = foundArticle;
			articleGroup.isCoupon = true;
			articleGroup.groups.push(
				...defaultsToArticleOption(foundArticle, [], foundArticle.defaults, this.order?.type, this.order.preorder.type)
			);

			articleGroup.quantity = 1;

			OrderUtils.addToOrder(this.order, articleGroup, this.analytics);

			this.repository.onOrderChange(this.order);
			sleep(300);
			if (dialog === AddToBasketCoupon.ADD_AND_PAY) {
				if (!AppComponent.largeScreen) {
					await this.navigationService.order();
				}
			}
		}
	}
}
