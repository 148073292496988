import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.page.html',
	styleUrls: ['faq.page.scss'],
})
export class FaqPage {
	static url = 'faq';
	environment = environment;

	listItems = [
		{
			title: 'Womit kann ich bezahlen?',
			description: 'Du kannst mit den üblichen Kreditkarten Mastercard und Visa Card bezahlen. Zudem akzeptieren wir PayPal.',
		},
		{
			title: 'Wie geht es nach meiner Bestellung weiter?',
			description:
				'Nach deiner Online-Bestellung bereiten wir die Gerichte frisch für dich zu. Du kannst bei Ankunft in der Raststätte bzw. beim Warten in der Raststätte auf dem Abholmonitor anhand deiner Bestellnummer verfolgen, wann deine Bestellung fertig ist. Sollte der Andrang etwas größer sein, kannst du es dir zwischendurch auf unseren Sitzplätzen gemütlich machen. Achte nur darauf, wann deine Bestellung fertig wird.',
		},
		{
			title: 'Kann ich auch mit Bargeld bezahlen?',
			description:
				'Derzeit kannst du in unserem Webshop noch nicht mit Bargeld bezahlen. Wenn du mit Bargeld zahlen möchtest, bestell doch einfach direkt im Restaurant.',
		},
		{
			title: 'Zu welchen Zeiten kann ich meine Online-Bestellungen aufgeben?',
			description:
				'Unsere Bestellzeiten können je nach Raststätten-Standort variieren. Die individuellen Öffnungszeiten findest du im Webshop in der Standortkarte unter „i“ wie Informationen.',
		},
		{
			title: 'Ich bin Vegetarier/-in. Habt ihr passende Produkte für mich?',
			description:
				'Ja, klar. Auch für dich haben wir superleckere Produkte. Schiebe den Veggie-Regler oberhalb der Produktübersicht nach rechts. Dann siehst du übersichtlich alle vegetarischen Gerichte.',
		},
		{
			title: 'Ich muss mich glutenfrei ernähren. Habt ihr Produkte, die ich essen kann?',
			description:
				'Wir nehmen das Thema glutenfrei und auch andere allergene Inhaltsstoffe sehr ernst. Leider können wir aufgrund der Herstellungsverfahren nicht sicher sein, dass unsere Produkte glutenfrei sind. Frag in diesem Fall lieber bei unserem Personal in der Raststätte nach. Hier kann dir sicherlich weitergeholfen werden.',
		},
		{
			title: 'Ich bin Allergiker. Kann ich bei euch bestellen?',
			description:
				'Wir haben Allergene und Zusatzstoffe ausgezeichnet. Klick einfach auf das „i“ direkt an den Produkten. Hier kannst du dich jederzeit informieren.',
		},
		{
			title: 'Wie lange dauert es, bis meine Bestellung fertig ist?',
			description:
				'Wir bereiten die Gerichte bei deiner Bestellung frisch zu. Aber keine Sorge. Wir sind gut organisiert und alles geht schnell. Leider kann es mal vorkommen, dass der Andrang etwas größer ist. Bitte hab Verständnis, wenn es dann mal etwas länger dauert.',
		},
		{
			title: 'Kann ich bei der Bestellung eine Uhrzeit zur Abholung festlegen?',
			description:
				'Leider geht das bei unseren Online-Bestellungen nicht. Solltest du Gast in unserem Hotel sein, kannst du gerne unser Personal ansprechen, wenn du zu einer bestimmten Uhrzeit dein Essen abholen möchtest.',
		},
		{
			title: 'Kann ich online einen Tisch bei euch reservieren?',
			description:
				'Deine Reservierung für einen Tisch nehmen wir gerne telefonisch entgegen. Die Rufnummer der Raststätte findest du im Webshop in der Standortkarte unter „i“ wie Informationen.',
		},
		{
			title: 'Kann ich meinen Hund mit in die Raststätte bringen?',
			description:
				'Wir lieben Hunde. Gerne kannst du deinen Hund angeleint in die Raststätte mitbringen. Wir stellen deinem Vierbeiner auch gerne einen frischen Napf mit Wasser zur Verfügung. Das ist bei uns eine Serviceselbstverständlichkeit.',
		},
		{
			title: 'Ich habe keine Bestellbestätigung bekommen. Was ist los?',
			description:
				'Trotz aller technischer Vorkehrungen kann so etwas in Ausnahmefällen vorkommen. Sprich gerne unser Personal im Restaurant an. Hier wird dir weitergeholfen.',
		},
	];

	constructor(
		private repository: RepositoryService,
		private navigationService: NavigationService
	) {}

	async goBack() {
		if (this.repository._order === null) {
			await this.navigationService.home();
		} else {
			await this.navigationService.menu();
		}
	}

	async toPrivacy() {
		await this.navigationService.privacy();
	}
	async toImprint() {
		await this.navigationService.imprint();
	}
}
